<template>
    <div style="background-color: #fff">
        <div class="top-button">
            <el-button @click="addType" size="mini" type="primary" icon="el-icon-plus">添加分类</el-button>
        </div>
        <div id="type-list" class="type-list">
            <gl-ul :list="typeData">
                <slot slot-scope="title">
                    <div  class="title sort" :data-id="title.item.id" :class="{disabled:!title.item.status}">
                    <span class="drag">
                        <i class="el-icon-rank"></i>
                    </span>
                        {{ title.item.title }}
                        <span class="operation">
                            <el-link style="margin-right: 15px" @click="addType(title.item)"  v-if="title.item.pid===0"  type="primary">添加</el-link>
                            <el-link @click="editType(title.item)"  type="primary">编辑</el-link>
                    </span>
                    </div>
                </slot>
            </gl-ul>
        </div>
        <form-dialog ref="formDialog" @closeFormDialog="submit" :title="form.title" :show="form.show"
                     :fields="form.fields"
                     :rules="form.rules" :data="form.data"></form-dialog>
    </div>
</template>
<script>
import F from '@/fields/fields';
import Sortable from 'sortablejs';
import glUl from "@/components/com/offline/glUl";

export default {
    name: "type2d",
    components: {
        glUl
    },
    data: function () {
        return {
            baseUrl: '/big/offline/glType',
            page: {
                search: {},
                // sort: {prop: 'create_time', order: 'descending'},
                sort: {},
                total: 1,
                pageSize: 100,
                pageNumber: 1,
            },
            ul:[

            ],
            actionName: '',
            form: {
                action_name: '',
                show: false,
                title: '',
                data: {},
                fields: [],
                rules: {},
            }
        }
    },
    computed: {
        typeData:function (){
            let ul=this.ul.map((v,index)=>{
                v.index=index;
                return v;
            });
            return this.$tools.loop(ul);
        }
    },
    mounted() {
        this.search();
    },
    methods: {
        condition: function (search) {
            this.page.search = search;
            this.page.pageNumber = 1;
            this.search();
        },
        search: function () {
            this.$com.get(this.baseUrl, {
                params: this.page,
            }).then(data => {
                this.page.total = data.data.total;
                this.page.pageNumber = data.data.pageNumber;
                this.page.pageSize = data.data.pageSize;
                this.ul=data.data.data;
                // this.tableData = data.data.data;
                // this.ul=this.$tools.loop(data.data.data);
                setTimeout(()=>{
                    this.createDrag();
                },100);
            });
        },
        submit: function (data) {
            if (data.type === 'cancel') {
                this.form.show = false;
                return;
            }
            switch (this.actionName) {
                case 'editType':
                    this.$com.put(this.baseUrl, data.data).then(userData => {
                        this.form.show = false;
                        this.$message.success('保存成功!');
                        this.ul.splice(this.form.index, 1, userData.data);
                    });
                    break;
                case 'addType':
                    this.$com.post(this.baseUrl, data.data).then((data) => {
                        this.$message.success('添加成功!');
                        this.form.show = false;
                        this.ul.push(data.data);
                    });
                    break;
            }
        },
        createDrag(){
            let dom = document.getElementById('type-list').getElementsByTagName('ul');
            [...dom].map((v,index)=>{
                new Sortable(v, {
                    handle: '.drag',
                    group: 'nested'+index,
                    animation: 150,
                    fallbackOnBody: true,
                    swapThreshold: 0.65,
                    onEnd: () => {
                        let data = {type: 'order', data: {}};
                        [...document.getElementById('type-list').getElementsByClassName('sort')].map((v, index) => {
                            data.data[v.dataset.id] = index;
                        });
                        this.$com.put(this.baseUrl, data).then(() => {
                            this.$message.success('保存成功!');
                        });
                    }
                });
            });
        },
        editType: function (data) {
            this.actionName = 'editType';
            let form = F.get('offlineList3dType', 'edit', this.ul[data.index]);
            form.index = data.index;
            this.form = form;
        },
        addType: function (data) {
            this.actionName = 'addType';
            this.form = F.get('offlineList3dType', 'add', {status: 1,pid:data.id||0});
        },
        sortChange: function (column) {
            if (!column.order) return;
            this.page.sort = {prop: column.prop, order: column.order};
            this.search();
        },
        sizeChange: function (size) {
            this.page.pageSize = size;
            this.search();
        },
        pageChange: function (page) {
            this.page.pageNumber = page;
            this.search();
        },
    }
}
</script>
<style scoped lang="less">
    .top-button{
        padding: 15px 30px;
    }

    .type-list{
        color: #333;
        .title{
            &.disabled{
                color: #fc2f2f;
            }
        }
    }
</style>
