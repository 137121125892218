<template>
    <ul class="ul">
        <li v-for="item in list" :key="item.id">
            <slot :item="item"></slot>
            <gl-ul @click-item="click" :list="item.children" v-if="item.children&&item.children.length>0">
                <template v-slot="tmp">
                    <slot :item="tmp.item"></slot>
                </template>
            </gl-ul>
        </li>
    </ul>
</template>
<script>
export default {
    name: "glUl",
    props: ['list'],
    methods:{
        click(item){
            let data=JSON.parse(JSON.stringify(item));
            console.log(data);
            this.$emit('clickItem',data);
        }
    }
}
</script>
<style lang="less" scoped>

.ul {
    li {
        user-select: none;
        color: #333;
        padding: 10px 30px;

        .title {
            display: flex;
            align-items: center;

            .drag {
                font-size: 14px;
                padding-right: 5px;
            }

            cursor: pointer;

            &:hover {
                .operation{
                    opacity: 1;
                    transform: translate(0,0);
                }
            }
            .operation{
                opacity: 0;
                transition: all ease-in-out 0.2s;
                transform: translate(50px,0);
                padding-left: 100px;
            }
        }

    }
}
</style>